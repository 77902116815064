<template>
    <div
        v-if="content && content.length"
        class="post-preview__text-field"
        :class="{'post-preview__text-field--loading': isLoading}"
    >
        <div class="post-preview__text-field--label">
            {{ label }}
        </div>
        <div
            class="post-preview__description-field--content froala-preview"
            v-html="content"
        />
    </div>
</template>

<script>
export default {
    name: 'PreviewDescriptionField',
    props: {
        content: String,
        label: String,
        isLoading: Boolean
    }
}
</script>

<style lang="scss" scoped>
    .froala-preview{
        max-width: 870px;
        width: 100%;
    }
</style>
