<template>
    <div
        class="post-preview__text-field"
        :class="{'post-preview__text-field--loading': isLoading}"
    >
        <div class="post-preview__text-field--label">
            {{ label }}
        </div>
        <div class="post-preview__text-field--content post-preview-text">
            {{ content ? content : '----' }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreviewTextField',
    props: ['content', 'label', 'isLoading']
}
</script>

<style lang="scss">
</style>
