<template>
    <div
        class="post-preview__img-field"
        :class="{'post-preview__text-field--loading': isLoading}"
    >
        <div class="post-preview__text-field--label">
            {{ label }}
        </div>
        <div
            v-if="content"
            class="post-preview-text"
        >
            <img
                :src="content"
                class="post-preview__img-field--image"
                alt=""
            >
        </div>
        <div
            v-else
            class="post-preview__text-field--content post-preview-text"
        >
            <b>You have no preview img</b>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreviewImgField',
    props: {
        content: String,
        label: String,
        isLoading: Boolean
    }
}
</script>

<style scoped>

</style>
