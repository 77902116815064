<template>
    <div class="post-preview">
        Link:
        <a
            v-if="form_data.url && !loading"
            :href="form_data.url"
            style="margin-top: 5px"
            target="_blank"
        >
            {{ form_data.url }}
        </a>
        <component
            :is="field.previewComponent"
            v-for="(field, id) in fields"
            :key="id"
            :content="getText(field.label, form_data[field.name])"
            :label="field.label"
            :is-loading="loading"
        />
    </div>
</template>

<script>
import PreviewDescriptionField from './components/PreviewDescriptionField';
import PreviewTextField from './components/PreviewTextField';
import PreviewImgField from './components/PreviewImgField';

export default {
    name: 'Preview',
    components: {
        PreviewImgField,
        PreviewTextField,
        PreviewDescriptionField
    },
    props: {
        form_data: Object,
        fields: Array,
        categories: Array,
        loading: Boolean
    },
    computed: {
        contentType() {
            return this.$route.meta.contentType
        }
    },
    activated() {
        this.$emit('update-data')
        this.$bus.$emit('setMode', 'edit')
    },
    beforeRouteLeave(to, from, next) {
        if (!to.name.includes(`create-post-${this.contentType}`)) {
            next()
            return
        }
        this.$emit('reset')
        this.$nextTick(() => {
            next()
        })
    },
    methods: {
        getText(name, value) {
            if (name !== 'Category') return value
            if (!this.categories) return value
            return this.categories.reduce((acc, c) => {
                const sub = c.categories.find((sub) => sub.value === value)
                if (sub) {
                    return `${c.text} > ${sub.text}`
                }
                return acc
            }, '')
        }
    }
}
</script>

<style lang="scss">
    @import '@/assets/styles/dashboard/components/content/_skeleton';
</style>
